import React from 'react'
import MDXSplitter from './MDXSplitter'
import './Modal.scss'

const Modal = ({children}) => {
  return (
    <div id='Modal'>
      <MDXSplitter>{children}</MDXSplitter>
    </div>
  )
}

export default Modal