import React from 'react'
import { Link } from 'gatsby'
import './LinkButton.scss'

const LinkButton = ({destination, children}) => {
  return (
    <div id='LinkButton'>
        <Link to={destination}>{children}</Link>
    </div>
  )
}

export default LinkButton