import React, {useState} from 'react'
import { graphql } from "gatsby"
import { MDXRenderer } from "gatsby-plugin-mdx"
import { MDXProvider } from "@mdx-js/react"
import Card from '../components/Card'
import Carousel from '../components/Carousel'
import FactBox from '../components/FactBox'
import LinkButton from '../components/LinkButton'
import Modal from '../components/Modal'
import SectionTemplate from '../components/SectionTemplate'
import Slide from '../components/Slide'

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import './home.scss'

const slides = [
  <SwiperSlide key={1}><div><div className="doctor" /></div></SwiperSlide>,
  <SwiperSlide key={2}><div className="happy-uterus" /></SwiperSlide>,
  <SwiperSlide key={3}><div className="clinic"/></SwiperSlide>,
]

export default function Home({data}) {

  const [modalOpen, setModalOpen] = useState(true);
  const shortcodes = { Card, Carousel, Slide, FactBox, LinkButton }

  return (
    <div className="Home">
      { modalOpen &&
        <Modal>
          <MDXProvider components={shortcodes}>
            <MDXRenderer>
            {data.mdx.frontmatter.modal}
            </MDXRenderer>
          </MDXProvider>
        </Modal>
      }
      <SectionTemplate showBreadCrumbs={false}>
        <div className="top">
          <Swiper
            centeredSlides={true}
            onSlideChange={() => setModalOpen(false)}
            navigation={{
              nextEl: '.swiper-button-next',
              prevEl: '.swiper-button-prev',
            }}
            pagination={{
              "clickable": true}}
          >
            {slides}
          </Swiper>
        </div>
        <div className="content">
          <MDXProvider components={shortcodes}>
            <MDXRenderer>
              {data.mdx.body}
            </MDXRenderer>
          </MDXProvider>
        </div>
      </SectionTemplate>
    </div>
  )
}

export const pageQuery = graphql`
  query {
    mdx(slug: {eq: ""}) {
      body
      frontmatter {
        modal
      }
    }
  }
`